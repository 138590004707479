<template>
  <div id="formEntry">
    <Sheet
      :value="value"
      width="100vw"
      has-footer
      no-padding
      @input="closeSheet"
    >
      <template #title>
        <slot name="title">{{ title }}</slot>
      </template>

      <template #default>
        <!-- form -->

        <div id="content-wrapper" class="row">
          <div class="col">
            <BaseScrollbar height="calc(100vh - 120px)" class="q-pa-md">
              <ValidationObserver ref="form">
                <div class="field-wrapper">
                  <div
                    class="row q-col-gutter-md q-mb-md containers"
                    @click="currentAssignees()"
                  >
                    <div class="col-0.5">
                      <div
                        id="avatar"
                        :class="{ 'is-active': currentAssignee }"
                      >
                        <BaseIcon :name="shareIcon" inherit-color />
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="q-mb-md title-2">Current Assignee</div>
                      <div class="descriptions">
                        To Delegate Workflow Request seamlessly when away:<br />
                        Choose backup users for your assignments on specific
                        dates and workflow
                      </div>
                    </div>
                    <div v-if="mode !== 'VIEW'" class="col-6">
                      <div class="q-mb-md">
                        <ValidationProvider
                          v-slot="{ errors }"
                          name="Current Assignee"
                          :rules="{ required: true }"
                        >
                          <SelectField
                            v-model="user"
                            label="Current Assignee"
                            is-mandatory
                            :options="users"
                            :error="errors[0]"
                            class="select-Field"
                            :is-readonly="mode !== 'NEW'"
                          />
                        </ValidationProvider>
                      </div>
                      <BaseButton
                        label="Search Active User Workflows"
                        color="secondary"
                        @click="getTicketListByUserId"
                      />
                    </div>
                  </div>

                  <BaseSeparator class="q-mb-md" />
                  <div class="row q-mb-md">
                    <div class="col-6">
                      <div class="q-ml-md">
                        <div class="title-2">Active Workflow List</div>

                        <div class="description descriptions">
                          Displays workflows where the selected users and
                          designated as active praticipants, defining roels eith
                          the listed workflows
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <ExpandableSearch
                        class="q-mr-sm expandables"
                        @search="searchRow"
                      />
                    </div>
                  </div>
                  <BaseScrollbar
                    style="max-height: calc(100vh - 285px)"
                    class="q-pa-xs"
                  >
                    <table>
                      <thead class="table-header">
                        <tr>
                          <th class="medium">Involved Workflows</th>
                          <th class="medium">Workflow Stage</th>
                          <th class="small">Tickets in Inbox</th>
                          <th class="medium">Alternative Assignee</th>
                          <th class="large">Reason for Selection</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr v-for="(ticket, index) in ticketList" :key="index">
                          <td class="medium">{{ ticket.name }}</td>
                          <td class="medium">{{ ticket.stage }}</td>
                          <td class="small">{{ ticket.inboxCount }}</td>
                          <td class="medium">
                            <ValidationProvider
                              v-slot="{ errors }"
                              name="Alternative Assignee"
                              :rules="{ required: true }"
                            >
                              <SelectField
                                v-model="ticket.assignee"
                                is-mandatory
                                :options="users"
                                :error="errors[0]"
                                class=""
                                :is-readonly="mode === 'VIEW'"
                              />
                            </ValidationProvider>
                          </td>
                          <td class="large">
                            <TextField
                              v-model="ticket.reason"
                              class=""
                              :is-readonly="mode === 'VIEW'"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </BaseScrollbar>

                  <BaseSeparator class="q-mb-md" />

                  <div
                    class="row q-col-gutter-md q-mb-md containers"
                    @click="delegateAbsences()"
                  >
                    <div class="col-0.5">
                      <div
                        id="avatar"
                        :class="{ 'is-active': delegateAbsence }"
                      >
                        <BaseIcon :name="fileCopy" inherit-color />
                      </div>
                    </div>
                    <div class="q-mb-sm col-4">
                      <div class="q-mb-md title-2">Delegate Absence Dates</div>
                      <div class="descriptions">
                        Specify start and end dates for leave to delegate
                        workflows from the current assignee to selected users
                      </div>
                    </div>
                    <div class="col-6">
                      <ValidationProvider
                        v-slot="{ errors }"
                        name="From Date"
                        :rules="{ required: true }"
                      >
                        <DateField
                          v-model="fromDate"
                          label="From Date"
                          is-mandatory
                          :error="errors[0]"
                          class="date-Field q-mb-md"
                          :emit-immediately="false"
                          :validation="true"
                          :is-readonly="mode === 'VIEW'"
                        />
                      </ValidationProvider>

                      <ValidationProvider
                        v-slot="{ errors }"
                        name="To Date"
                        :rules="{ required: true }"
                      >
                        <DateField
                          v-model="toDate"
                          label="To Date"
                          is-mandatory
                          :error="errors[0]"
                          class="date-Field"
                          :emit-immediately="false"
                          :validation="true"
                          :is-readonly="mode === 'VIEW'"
                        />
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
              </ValidationObserver>

              <BaseSeparator class="q-mb-md" />

              <div class="row containers" @click="workflowAndRequests()">
                <div class="col-0.5">
                  <div id="avatar" :class="{ 'is-active': workflowAndRequest }">
                    <BaseIcon :name="workFlowIcon" inherit-color />
                  </div>
                </div>

                <div class="col-4 workflowAndRequests">
                  <div class="q-mb-md title-2">
                    Workflow and Request Delegation Action
                  </div>
                  <div class="descriptions">
                    Define the actions to be taken upon the delegation of
                    selectcted workflows and requests, ensuring seamless
                    transition and efficient workflow management.
                  </div>
                </div>
                <div class="col-6">
                  <CheckboxField
                    v-model="isRedirectAllNewRequest"
                    label="Redirect all new requests to the Alternateed Assignee during their leave period"
                    class="q-mb-md"
                    :is-disabled="true"
                  />

                  <CheckboxField
                    v-model="isTransferCurrentRequest"
                    label="Transfer all current requests to the designated Reassign User"
                    class="q-mb-md"
                    :is-disabled="mode === 'VIEW'"
                  />

                  <CheckboxField
                    v-model="isNotification"
                    label="Notification sent to Reassign User"
                    class="q-mb-md"
                    :is-disabled="mode === 'VIEW'"
                  />
                </div>
              </div>
            </BaseScrollbar>
          </div>
        </div>

        <!-- ... -->
      </template>

      <template v-if="mode !== 'VIEW'" #footer>
        <BaseButton
          is-flat
          label="cancel"
          class="q-mr-sm"
          @click="closeSheet"
        />

        <BaseButton
          :label="mode === 'NEW' ? 'submit' : 'Save'"
          @click="addLeaveEntries()"
        />
      </template>
    </Sheet>
    <ConfirmLeave
      v-model="isConfirmLeaveVisible"
      :workspace="LeaveData"
      @submit="submitClick"
    />
  </div>
</template>

<script>
import Sheet from "@/components/common/popup/Sheet.vue";

import TextField from "@/components/common/form/text-field/TextField.vue";
import DateField from "@/components/common/form/date-field/DateField.vue";
import SelectField from "@/components/common/form/select-field/SelectField.vue";
import CheckboxField from "@/components/common/form/checkbox-field/CheckboxField.vue";
import ExpandableSearch from "@/components/common/ExpandableSearch.vue";
import { user, transaction } from "@/api/factory.js";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import ConfirmLeave from "./ConfirmLeave.vue";
export default {
  name: "LeaveFormEntrySheet",

  components: {
    Sheet,
    ValidationObserver,
    ValidationProvider,
    TextField,
    DateField,
    CheckboxField,
    SelectField,
    ConfirmLeave,
    ExpandableSearch,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    mode: {
      type: String,
      default: "NEW",
    },

    formModel: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      user: "",
      workFlowIcon: "mdi-robot",
      userName: "",
      users: [],
      LeaveData: {},
      isConfirmLeaveVisible: false,
      fileCopy: "mdi-file-replace",
      currentAssignee: true,
      delegateAbsence: false,
      workflowAndRequest: false,
      shareIcon: "mdi-share-variant",
      fromDate: "",
      toDate: "",
      isRedirectAllNewRequest: true,
      isTransferCurrentRequest: false,
      reason: "",
      totalRequest: "",
      totalTickets: "",
      ticketList: [
        {
          assignee: 0,
          inboxCount: 0,
          name: "",
          reason: null,
          stage: "",
          workflowId: 0,
        },
      ],
      securityData: [
        {
          assignee: 0,
          inboxCount: 0,
          name: "",
          reason: null,
          stage: "",
          workflowId: 0,
        },
      ],
      isNotification: false,
    };
  },

  computed: {
    title() {
      if (this.mode === "VIEW") return "View Entry";
      if (this.mode === "EDIT") return "Edit Entry";

      return "Add Entry";
    },
  },

  watch: {
    mode: {
      deep: true,
      immediate: true,
      handler() {
        if (this.mode === "EDIT" || this.mode === "VIEW") {
          if (this.formModel) {
            this.user = this.formModel.userId;
            this.ticketList = JSON.parse(this.formModel.settings);
            this.fromDate = this.formModel.fromDate;
            this.toDate = this.formModel.toDate;
            this.isTransferCurrentRequest =
              this.formModel.isAllInbox === "Yes" ? true : false;
            this.isNotification =
              this.formModel.isNotification === "Yes" ? true : false;
          }
        }
      },
    },
  },

  created() {
    this.getUsers();
  },

  methods: {
    searchRow(search) {
      console.log(this.securityData, "securityData");
      let searchData = this.securityData.map((ticket) => ({
        name: ticket.name,
        stage: ticket.stage,
      }));

      if (search) {
        this.ticketList = searchData.filter((item) => {
          return (
            item.name.toLowerCase().includes(search.toLowerCase()) ||
            item.stage.toLowerCase().includes(search.toLowerCase())
          );
        });
      } else {
        this.ticketList = this.securityData;
      }
      console.log(this.ticketList, "this,ticketlist");
    },

    workflowAndRequests() {
      this.workflowAndRequest = true;
      this.currentAssignee = false;
      this.delegateAbsence = false;
    },
    currentAssignees() {
      this.workflowAndRequest = false;
      this.currentAssignee = true;
      this.delegateAbsence = false;
    },
    delegateAbsences() {
      this.workflowAndRequest = false;
      this.currentAssignee = false;
      this.delegateAbsence = true;
    },
    closeSheet() {
      this.reset();
      this.$emit("input", false);
    },

    async getUsers() {
      const { error, payload } = await user.getUserList();

      if (error) {
        this.$alert.error("Error fetching users");
        return;
      }

      this.users =
        payload &&
        payload.map((user) => ({
          id: this.$nano.id(),
          label: user.value,
          value: Number(user.id),
        }));
    },

    async getTicketListByUserId() {
      this.$store.commit("showLoadingBar");

      const { error, payload } = await transaction.getTicketListByUserId(
        this.user
      );

      if (error) {
        this.$alert.error(error);
        return;
      }

      this.$store.commit("hideLoadingBar");

      console.log(payload);

      this.ticketList = payload;
      this.securityData = payload;
    },

    addLeaveEntries() {
      this.userName = this.users.find((user) => user.value === this.user).label;

      const ChangedAssignee = this.ticketList.filter(
        (item) => item.assignee !== null
      );

      this.totalRequest = ChangedAssignee.length;

      let totalInboxCount = 0;

      ChangedAssignee.forEach((ticket) => {
        totalInboxCount += ticket.inboxCount;
      });

      this.totalTickets = totalInboxCount;
      let inputs = {
        userName: this.userName,
        fromDate: this.fromDate,
        toDate: this.toDate,
        totalRequest: this.totalRequest,
        totalTickets: this.totalTickets,
      };

      this.LeaveData = inputs;
      this.isConfirmLeaveVisible = true;
    },

    async submitClick() {
      this.$store.commit("showLoadingBar");
      let input = {
        userId: this.user,
        fromDate: this.fromDate,
        toDate: this.toDate,
        isAllInbox: this.isTransferCurrentRequest,
        isNotification: this.isNotification,
        settingsJson: JSON.stringify(this.ticketList),
      };

      if (this.mode === "NEW") {
        const { error } = await transaction.addLeaveEntries(input);

        if (error) {
          this.$alert.error(error);
          return;
        }

        this.$alert.success(`Leave entry added successfully`);
      } else {
        const { error } = await transaction.updateLeaveEntries(
          this.formModel.id,
          input
        );

        if (error) {
          this.$alert.error(error);
          return;
        }

        this.$alert.success(`Leave entry edited successfully`);
      }

      this.$store.commit("hideLoadingBar");

      // console.log(payload);
      this.reset();
      this.$emit("refresh");
    },

    reset() {
      this.user = 0;
      this.fromDate = "";
      this.toDate = "";
      this.ticketList = [
        {
          assignee: 0,
          inboxCount: 0,
          name: "",
          reason: null,
          stage: "",
          workflowId: 0,
        },
      ];
      this.isNotification = false;
      this.isTransferCurrentRequest = false;
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;

  .table-header {
    background-color: #eeeeee;
  }

  tr {
    height: 40px;
  }

  th {
    padding: 4px 4px;
    text-align: left;
    font-weight: 500;
    overflow: auto;
    max-height: 300px;
    text-transform: capitalize;

    &.extra-small {
      width: 20px;
    }

    &.small {
      width: 70px;
    }

    &.medium {
      width: 140px;
    }

    &.large {
      width: 210px;
    }

    &.actions {
      width: 48px;
    }
  }

  th,
  td {
    border: 1px solid var(--divider-color);

    &.small {
      width: 70px;
    }

    &.medium {
      width: 140px;
    }

    &.large {
      width: 210px;
    }
  }

  td {
    padding: 4px;
    vertical-align: top;
  }

  td .label {
    display: flex;
    align-items: center;
    height: 40px;
    padding-left: 4px;
    color: var(--icon-color);
  }
}

.description {
  @extend .text-sm;
  margin-top: 4px;
  color: var(--icon-color);
}

.select-Field {
  width: 250px;
}

.date-Field {
  width: 410px;
}

.descriptions {
  width: 366px;
}

.workflowAndRequests {
  padding-left: 16px;
}

#avatar {
  height: 36px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--icon-color);
  background-color: var(--component-bg-color-inferior);
  border-radius: 100%;

  &.is-active {
    color: $white;
    background-color: var(--secondary);
  }
}

.containers {
  padding: 20px 0 36px 16px;
}

.border {
  border: 1px solid var(--divider-color) !important;
  border-radius: 5px;
  width: 10%;
}
.expandables {
  margin-top: 26px;
  float: right;
}
</style>
