<template>
  <div id="leave-browse">
    <Portal to="primary-action">
      <BaseButton
        icon="eva-plus"
        label="Add Entry"
        class="q-ml-md"
        @click="addLeaveEntry"
      />
    </Portal>

    <Portal to="action-bar">
      <ItemActions
        item="leave entry"
        :columns.sync="columns"
        :group-by.sync="groupBy"
        :mode="mode"
        :order.sync="sortBy.order"
        :filter-by.sync="filterBy"
        :active-view.sync="activeView"
        :criteria.sync="sortBy.criteria"
        @refresh="getLeaveEntries"
        @search="searchRow"
        @update="getLeaveEntries"
      />
    </Portal>

    <div class="content">
      <template v-if="totalItems">
        <ItemActionChips
          :sort-by="sortBy"
          :columns="columns"
          :group-by="groupBy"
          :filter-by="filterBy"
          @clearSortBy="clearSortBy"
          @clearGroupBy="clearGroupBy"
          @clearFilterBy="clearFilterBy"
        />

        <LeaveEntryGrid
          v-if="activeView === 'GRID'"
          :mode="mode"
          :items="items"
          :columns="columns"
          @edit="editFormEntry"
          @more="viewFormEntry"
          @delete="showDeleteConfirmation"
          @restore="showRestoreConfirmation"
        />

        <LeaveEntryList
          v-if="activeView === 'LIST'"
          :mode="mode"
          :items.sync="items"
          :columns="columns"
          :select-all="overallSelectAll"
          @edit="editFormEntry"
          @more="viewFormEntry"
          @delete="showDeleteConfirmation"
          @restore="showRestoreConfirmation"
        />
      </template>
      <template v-else>
        <StateWrapper
          icon="mdi-text-box-outline"
          title="NO DATA"
          description="No data available at the moment"
          style="margin-top: 20px"
        />
      </template>
    </div>

    <Pagination
      :total-items="totalItems"
      :current-page.sync="currentPage"
      :items-per-page.sync="itemsPerPage"
      @loadAll="loadAllFormEntries"
    />

    <ConfirmDeleteLeaveEntry
      v-model="isDeleteConfirmationVisible"
      :form-entry-id="selectedLeaveFormEntry?.id"
      :total-items="totalItems"
      :mode="mode"
      @delete="updateFormEntry"
    />

    <ConfirmRestoreLeaveEntry
      v-model="isRestoreConfirmationVisible"
      :form-entry-id="selectedLeaveFormEntry?.id"
      @restore="updateFormEntry"
    />

    <LeaveBuilder
      v-model="isLeaveFormEntrySheetVisible"
      :form-model="selectedLeaveFormEntry"
      :mode="entryMode"
      @refresh="getLeaveEntries"
      @input="
        {
          entryMode = '';
          isLeaveFormEntrySheetVisible = false;
        }
      "
    />
  </div>
</template>

<script>
import DataType from "@/constants/data-type.js";
import { transaction, user } from "@/api/factory.js";
import { Portal } from "portal-vue";
import StateWrapper from "@/components/common/state/StateWrapper.vue";
import Pagination from "@/components/common/display/Pagination.vue";
import ItemActions from "@/components/common/display/item-actions/ItemActions.vue";
import ItemActionChips from "@/components/common/display/ItemActionChips.vue";
import LeaveEntryGrid from "./components/LeaveEntryGrid.vue";
import LeaveEntryList from "./components/LeaveEntryList.vue";
import ConfirmDeleteLeaveEntry from "./components/ConfirmDeleteLeaveEntry.vue";
import ConfirmRestoreLeaveEntry from "./components/ConfirmRestoreLeaveEntry.vue";
import LeaveBuilder from "@/views/leave/views/leave-builder/LeaveBuilder";

export default {
  name: "LeaveBrowseAndTrash",

  components: {
    Portal,
    StateWrapper,
    ItemActionChips,
    ItemActions,
    Pagination,
    LeaveEntryGrid,
    LeaveEntryList,
    ConfirmDeleteLeaveEntry,
    ConfirmRestoreLeaveEntry,
    LeaveBuilder,
  },

  props: {
    mode: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      columns: [
        {
          id: this.$nano.id(),
          name: "itemid",
          label: "Entry #",
          type: "TITLE",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "createdBy",
          label: "Created By",
          type: "SUBTITLE",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SINGLE_SELECT,
          default: "USERS",
        },
        {
          id: this.$nano.id(),
          name: "createdAt",
          label: "Created At",
          type: "NORMAL",
          isVisible: false,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.DATE_TIME,
        },
        {
          id: this.$nano.id(),
          name: "modifiedBy",
          label: "Modified By",
          type: "NORMAL",
          isVisible: false,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SINGLE_SELECT,
          default: "USERS",
        },
        {
          id: this.$nano.id(),
          name: "modifiedAt",
          label: "Modified At",
          type: "NORMAL",
          isVisible: false,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.DATE_TIME,
        },
        {
          id: this.$nano.id(),
          name: "user",
          label: "User",
          type: "NORMAL",
          isVisible: true,
          isSortable: false,
          isGroupable: false,
          dataType: "SHORT_TEXT",
        },
        {
          id: this.$nano.id(),
          name: "fromDate",
          label: "From Date",
          type: "NORMAL",
          isVisible: true,
          isSortable: false,
          isGroupable: false,
          dataType: "SHORT_TEXT",
        },
        {
          id: this.$nano.id(),
          name: "toDate",
          label: "To Date",
          type: "NORMAL",
          isVisible: true,
          isSortable: false,
          isGroupable: false,
          dataType: "SHORT_TEXT",
        },
        {
          id: this.$nano.id(),
          name: "isAllInbox",
          label: "Current Inbox Request Transfer",
          type: "NORMAL",
          isVisible: true,
          isSortable: false,
          isGroupable: false,
          dataType: "SHORT_TEXT",
        },
        {
          id: this.$nano.id(),
          name: "isNotification",
          label: "Notification",
          type: "NORMAL",
          isVisible: true,
          isSortable: false,
          isGroupable: false,
          dataType: "SHORT_TEXT",
        },
      ],
      activeView: "GRID",

      isDeleteConfirmationVisible: false,
      isRestoreConfirmationVisible: false,
      sortBy: {
        criteria: "",
        order: "DESC",
      },
      groupBy: "",
      filterBy: [],
      totalItems: 0,
      itemsPerPage: 50,
      currentPage: 1,

      entryMode: "NEW",
      leaveEntries: [],
      leaveEntriesData: [],

      isLeaveFormEntrySheetVisible: false,
      selectedLeaveFormEntry: {},
      userList: [],
    };
  },

  computed: {
    items() {
      return this.leaveEntries.map(({ key, value }) => ({
        key: this.groupBy,
        value: key,
        data: value.map((entry) => ({
          id: entry.id,
          itemid: `Entry #${entry.id}`,
          createdBy: entry.createdBy,
          createdAt: this.$day.format(entry.createdAt),
          modifiedBy: entry.modifiedBy,
          modifiedAt: this.$day.format(entry.modifiedAt),
          icon: "mdi-text-box",
          user: this.getUserEmail(entry.userId),
          userId: entry.userId,
          fromDate: entry.fromDate,
          toDate: entry.toDate,
          settings: entry.settings,
          isAllInbox: entry.isAllInbox,
          isNotification: entry.isNotification,
        })),
      }));
    },
  },

  watch: {},

  mounted() {
    this.$watch(
      (vm) => [vm.mode, vm.sortBy, vm.groupBy, vm.currentPage, vm.itemsPerPage],
      () => this.getLeaveEntries(),
      { deep: true, immediate: true }
    );
  },

  methods: {
    clearSortBy() {
      this.sortBy = {
        criteria: "",
        order: "DESC",
      };
    },

    clearGroupBy() {
      this.groupBy = "";
    },

    findFormEntry(formEntryId) {
      const leaveEntries = [];
      this.items.forEach((item) => leaveEntries.push(...item.data));
      return leaveEntries.find((entry) => entry.id === formEntryId);
    },

    addLeaveEntry() {
      this.entryMode = "NEW";

      this.selectedLeaveFormEntry = {};
      this.isLeaveFormEntrySheetVisible = true;
    },

    editFormEntry(formEntryId) {
      this.entryMode = "EDIT";

      this.selectedLeaveFormEntry = this.findFormEntry(formEntryId);
      this.isLeaveFormEntrySheetVisible = true;
    },

    viewFormEntry(formEntryId) {
      this.entryMode = "VIEW";

      this.selectedLeaveFormEntry = this.findFormEntry(formEntryId);
      this.isLeaveFormEntrySheetVisible = true;
    },

    showDeleteConfirmation(formEntryId) {
      this.selectedLeaveFormEntry = this.findFormEntry(formEntryId);

      this.isDeleteConfirmationVisible = true;
    },

    showRestoreConfirmation(formEntryId) {
      this.selectedLeaveFormEntry = this.findFormEntry(formEntryId);

      this.isRestoreConfirmationVisible = true;
    },

    loadAllFormEntries() {
      this.itemsPerPage = this.totalItems;
      this.currentPage = 1;
    },

    /* api functions */

    async updateFormEntry(payload) {
      this.$store.commit("showLoadingBarPage");

      const { error } = await transaction.updateLeaveEntries(
        this.selectedLeaveFormEntry.id,
        payload
      );

      if (error) {
        this.$alert.error(error);
        return;
      }

      this.$store.commit("hideLoadingBarPage");

      const mode = payload.isDeleted ? "deleted" : "restored";
      const name = this.selectedLeaveFormEntry.itemid;
      this.$alert.success(`Leave entry "${name}" ${mode} successfully`);

      this.getLeaveEntries();
    },

    /* ... */

    searchRow(search) {
      if (search) {
        this.leaveEntries = this.leaveEntriesData.map(({ key, value }) => ({
          key: key,
          value: value.filter((row) => {
            for (let cell in row) {
              console.log(cell);
              let index = this.columns.findIndex((column) => {
                return column.name === cell;
              });
              console.log(index, row[cell]);
              if (index >= 0 && row[cell]) {
                if (
                  String(row[cell]).toLowerCase().includes(search.toLowerCase())
                ) {
                  return row;
                }
              }
            }
          }),
        }));
      } else {
        this.leaveEntries = this.leaveEntriesData;
      }
    },

    clearFilterBy(id, index) {
      this.filterBy[id].filters.splice(index, 1);
      if (this.filterBy[id].filters.length == 0) {
        this.filterBy[id].filters = [];
        this.filterBy.splice(id, 1);
      }
      if (this.filterBy.length == 0) this.filterBy = [];
      this.getLeaveEntries();
    },

    async getLeaveEntries() {
      this.$store.commit("showLoadingBarPage");

      const { error, payload } = await transaction.getLeaveEntries({
        mode: this.mode,
        filterBy: [],
      });

      this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }
      console.log(payload);
      await this.getUsers();
      // const { data, meta } = payload;
      let result = [
        {
          key: "",
          value: payload.map((entry) => ({
            id: entry.id,
            itemid: `Entry #${entry.id}`,
            createdBy: entry.createdByEmail,
            createdAt: this.$day.format(entry.createdAt),
            modifiedBy: entry.modifiedByEmail,
            modifiedAt: this.$day.format(entry.modifiedAt),
            icon: "mdi-text-box",
            user: this.getUserEmail(entry.userId),
            userId: entry.userId,
            fromDate: entry.fromDate,
            toDate: entry.toDate,
            settings: entry.settingsJson,
            isAllInbox: entry.isAllInbox ? "Yes" : "No",
            isNotification: entry.isNotification ? "Yes" : "No",
          })),
        },
      ];

      this.leaveEntries = result || [];
      this.leaveEntriesData = result;
      this.totalItems = result[0].value.length || 0;
      this.isLeaveFormEntrySheetVisible = false;
    },

    async getUsers() {
      const { error, payload } = await user.getUserList();

      if (error) {
        this.$alert.error("Error fetching users");
        return;
      }

      this.userList =
        payload &&
        payload.map((user) => ({
          id: this.$nano.id(),
          label: user.value,
          value: Number(user.id),
        }));
    },

    getUserEmail(id) {
      let user = this.userList.find((user) => user.value === id);
      if (user) {
        // console.log(user);
        return user.label;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#leave-browse {
  .content {
    padding-top: 8px;
    min-height: calc(100vh - 248px);
  }
}
</style>
